@tailwind components;
@tailwind utilities;

html, body {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'SourceSansPro';
    src: url('./resources/fonts/source-sans-pro-bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('./resources/fonts/inter-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('./resources/fonts/inter-medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('./resources/fonts/inter-semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
